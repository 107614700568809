<template>
    <router-view />
</template>

<script setup>
import { onMounted } from "vue";
import { getCaptchaKey } from "./utilities/utility";

onMounted(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${getCaptchaKey()}`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
});
</script>

<style lang="scss">
@import "@/styles/forms.css";
@import "@/styles/modal.css";
@import "@/styles/style.css";
</style>
