import { createRouter, createWebHashHistory } from "vue-router";
import { getRedirected, getToken, setToken, initSite, isDaimler, isMercedes, isGeneral } from "@/utilities/utility";
import { nextTick } from "vue";
import { login, getoidcUrl } from "@/utilities/api";

await initSite();

// Route for mercedes forms
const mercedesRoutes = [
    {
        path: "/success",
        name: "success",
        component: () => import("@/views/SuccessView.vue"),
    },
    {
        path: "/form",
        name: "form",
        component: () => import("@/views/mercedes/FormView.vue"),
        children: [
            {
                path: "audioevent",
                name: "audioeventForm",
                component: () => import("@/forms/mercedes/AudioEventForm.vue"),
            },
            {
                path: "webcast",
                name: "webcastForm",
                component: () => import("@/forms/WebcastForm.vue"),
            },
            {
                path: "theater",
                name: "theaterForm",
                component: () => import("@/forms/mercedes/TheaterForm.vue"),
            },
            {
                path: "pro",
                name: "proForm",
                component: () => import("@/forms/ProForm.vue"),
            },
        ],
    },
    {
        path: "/order/start",
        name: "redirect",
        component: () => import("@/views/RedirectView.vue"),
    },
    {
        path: "/",
        name: "home",
        component: () => import("@/views/mercedes/HomeView.vue"),
    },
];

// Route for daimler forms
const daimlerRoutes = [
    {
        path: "/success",
        name: "success",
        component: () => import("@/views/SuccessView.vue"),
    },
    {
        path: "/form",
        name: "form",
        component: () => import("@/views/daimler/FormView.vue"),
        children: [
            {
                path: "webcast",
                name: "webcastForm",
                component: () => import("@/forms/WebcastForm.vue"),
            },
            {
                path: "pro",
                name: "proForm",
                component: () => import("@/forms/ProForm.vue"),
            },
        ],
    },
    {
        path: "/order/start",
        name: "redirect",
        component: () => import("@/views/RedirectView.vue"),
    },
    {
        path: "/",
        name: "home",
        component: () => import("@/views/daimler/HomeView.vue"),
    },
];

// Route for general forms
const generalRoutes = [
    {
        path: "/success",
        name: "success",
        component: () => import("@/views/SuccessView.vue"),
    },
    {
        path: "/form",
        name: "form",
        component: () => import("@/views/general/FormView.vue"),
        children: [
            {
                path: "audioevent",
                name: "audioEventForm",
                component: () => import("@/forms/general/AudioEventForm.vue"),
            },
        ],
    },
];

let routes;
if (isGeneral()) routes = generalRoutes;
if (isMercedes()) routes = mercedesRoutes;
if (isDaimler()) routes = daimlerRoutes;

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    await initSite();

    if (isGeneral()) {
        if (to.name === "success") {
            next();
        } else if (to.name === "audioEventForm") {
            next();
        } else {
            next("/form/audioevent");
        }
    }
    if (isMercedes() || isDaimler()) {
        const token = getToken();

        // if user is not redirected, start login process
        if (to.name !== "redirect" && !getRedirected() && token === "") {
            getoidcUrl();
            // when user is redirected, continue login process
        } else if (token === "") {
            const code = new URLSearchParams(location.href.split("?")[1]).get("code");

            const userInfo = await login(code);

            // if login worked store the token
            if (userInfo) {
                setToken(userInfo);
                await nextTick(() => {
                    next("/");
                });
                // if login didn't work restart the login process
            } else {
                console.error(`Login not possible with code: ${code}`);
                getoidcUrl();
            }
        }
        next();
    }
});

export default router;
